<template>
	<main>
		<HeaderTab
			:title="$t('global.titre_qualification')"
			:swiper_tabs="swiper_tabs"
		/>

 		<div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
							<router-view>
							</router-view>
                        </div>
                    </div>
                </div>
            </div>
 		</div>
	</main>
</template>

<script>
import Qualification from '@/mixins/Qualification'

export default {
	name: 'Qualification',
	mixins: [Qualification],
	data: () => ({
		swiper_tabs: [],
	}),
	components: {
		HeaderTab: () => import('@/components/HeaderTab')
	},
	created() {
		this.loadQualificationTypes()
	},
	methods: {
		async loadQualificationTypes() {
			const types = await this.getQualificationTypes()

			this.swiper_tabs = []

			types.forEach(type => {
				const code = type.qualificationtype_libelle
				this.swiper_tabs.push({
					label: this.$t(`types.qualification.${code}`),
					href : 'qualificationListe',
					params: { qualificationtype_libelle: code },
					active: false,
					name: code
				})
			})
		}
	}
}
</script>
